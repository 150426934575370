// import * as filFunc from './modules/functions.js'
// import $ from 'jquery'
// import 'jquery-ui/ui/widgets/tabs.js'

import "./plugins.js";
import Swiper from "swiper";
import tingle from "tingle.js";

const page = document.querySelector(".page");
const header = document.querySelector(".header");
const searchBtn = document.querySelector(".menu-nav__link");
const searchBlock = document.querySelector(".search");
const searchForm = document.querySelector(".search__form");
const searchInput = document.querySelector(".search__input");

window.addEventListener("scroll", (e) => {
  const scrollPosition = window.scrollY;
  if (scrollPosition < 5 && !searchBlock.classList.contains("search-open")) {
    header.classList.remove("header__bg");
  } else {
    header.classList.add("header__bg");
  }
});

searchBtn.addEventListener("click", (e) => {
  // e.preventDefault();

  header.classList.add("header__bg");
  searchBlock.classList.toggle("search-open");
  if (searchBlock.classList.contains("search-open")) {
    searchBtn.innerText = "Закрыть поиск";
  } else {
    closeSearch();
  }
});

const closeSearch = () => {
  searchInput.value = "";
  searchBlock.classList.remove("search-open");
  searchBtn.innerText = "найти запчасть";
  const list = searchBlock.querySelector(".search__list");
  list?.remove();
};

searchForm.addEventListener("submit", function (e) {
  e.preventDefault();
  const formData = new FormData(e.target);

  const searchTerm = formData.get("q");

  const url = "/catalog/category/search";

  fetch(`${url}?q=${searchTerm}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  })
    .then((response) => response.text())
    .then((html) => {
      searchBlock.insertAdjacentHTML("beforeend", html);

      searchBlock
        .querySelector(".search__close")
        .addEventListener("click", () => {
          closeSearch();
        });
      /**   -----  Слайдер в товаре  ------ */
      let swiper = new Swiper(".swiper-thumb", {
        loop: true,
        spaceBetween: 10,
        slidesPerView: 2,
        freeMode: true,
        watchSlidesProgress: true,
        slideToClickedSlide: true,
      });

      const items = document.querySelectorAll(".item");
      items.forEach(function (item) {
        const thumbs = item.querySelectorAll(".swiper-thumb img");
        thumbs.forEach(function (thumb) {
          thumb.addEventListener("click", () => {
            const mainImg = item.querySelector(".main_img");
            mainImg.setAttribute("src", thumb.getAttribute("src"));
          });
        });
      });

      /**   -----  Модалка для картинки  ------ */
      const modal = new tingle.modal({
        closeMethods: ["overlay", "button", "escape"],
        closeLabel: "Close",
        cssClass: ["modal-image"],
      });
      document.querySelectorAll(".main_img").forEach((image) => {
        image.addEventListener("click", () => {
          modal.setContent(`<img src="${image.src}" alt="${image.alt}">`);
          modal.open();
        });
      });
    })
    .catch((error) => {
      console.error(error); // логируем ошибку в консоль
    });
});

// if (window.innerWidth < 768) {
//   // Получаем все элементы с атрибутом data-aos
//   const aosElements = document.querySelectorAll("[data-aos]");

//   // Удаляем атрибут data-aos со всех элементов
//   for (var i = 0; i < aosElements.length; i++) {
//     aosElements[i].removeAttribute("data-aos");
//   }
// }
